iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

html, body {
    width: 100%;
    margin: 0;
    padding: 0;
}

.tabulator,
body {
    font-size: 12px !important; /* Change this to your desired font size */
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.column25 {
    width: 25%;
}

.column50 {
    width: 50%;
}

.column75 {
    width: 75%;
}

.fullRow {
    width: 100%;
    flex-grow: 1;
}

iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

.tabulator,
body {
    font-size: 12px !important; /* Change this to your desired font size */
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.column25 {
    width: 25%;
}

.column50 {
    width: 50%;
    /*border: 1px solid #ccc; !* For visual reference *!*/

}

.column75 {
    width: 75%;
}

.fullRow {
    width: 100%;
    flex-grow: 1;
}

.viewerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* workaround: viewer container init does not include other divs height like buttons below */
    padding-bottom: 40px;
    height: 100%; /* Ensure the row takes up the full height */
    /*border: 1px solid #00ff00; !* For visual reference *!*/
}

.viewerContainer > * {
    position: relative;
    bottom: 0;
    width: 100%;
    /*border: 1px solid #ccc; !* For visual reference *!*/
}

.assetContainer,
.assetInfoBar,
.assetRelationShipsContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    /*border: 1px solid #FF0000; !* For visual reference *!*/
}


.assetContainer,
.assetInfoBar,
.assetRelationShipsContainer > * {
    position: relative;
    bottom: 0;
    width: 100%;
}

.getDocumentsButton {

}
input,
.form-select,
.btn {
    /*padding: 0.5rem !important; !* Adjust these values as needed *!*/
    font-size: 0.7rem !important;
    padding: 0.25rem 0.5rem !important; /* Adjust these values as needed */
    margin: 0.25rem !important;
    /*display: inline!important;*/

}
.accordion-header{
    font-size: 0.7rem !important;
}
.accordion-header span {
    margin-right: 4px;
}
.tabulator-row.clicked {
    background-color: #c8e6c9!important; /* Light green background */
}
.tabulator-cell.non-matching-custom-prop {
    background-color: #888888; pointer-events: none;
}
.subtable {
    /*display: none;*/
    height: 70px!important;

}
.tabulator-headers > :first-child {
    display: none!important;
}